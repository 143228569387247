import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

import type { ComputedRef } from 'vue';
import type {
    DivisionData,
    NotificationData,
    SharedData,
    TenantData,
    MemberData,
    UserData,
    SquareData,
    MonerisData,
} from '@aspect/shared/types/generated';

type Errors = Record<string, string>;
type ErrorBag = Record<string, Errors>;

export interface PageProps extends SharedData {
    errors: Errors & ErrorBag;
}

export function usePageProps(props: any = null): ComputedRef<PageProps> {
    const page = usePage();
    const pageProps = computed(() => props ?? page.props);

    return computed(() => ({
        tenant: pageProps.value.tenant as TenantData,
        division: pageProps.value.division as DivisionData,
        divisions: pageProps.value.divisions as DivisionData[] | null,
        square: pageProps.value.square as SquareData | null,
        moneris: pageProps.value.moneris as MonerisData | null,
        member: pageProps.value.member as MemberData | null,
        user: pageProps.value.user as UserData | null,
        notification: pageProps.value.notification as NotificationData | null,
        flashData: pageProps.value.flashData as any,
        errors: pageProps.value.errors as Errors & ErrorBag,
        features: pageProps.value.features || {} as Record<string, boolean | string>,
    }));
}
